body {
  cursor: url("./assets/images/cursor-ico.svg"), auto;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #0b0a0a;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #908b8b !important;
  border-radius: 99px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #908b8b !important;
}

/* Handling trading view chart height */

.tradingview-widget-container iframe {
  height: 457px !important;
}

/* Custom Calendar ---- React Calendar */

.react-calendar {
  padding: 24px !important;
}

.react-calendar__navigation {
  display: flex !important;
  justify-content: space-between !important;
}

.react-calendar__navigation__prev2-button {
  display: none !important;
}

.react-calendar__navigation__next2-button {
  display: none !important;
}

.react-calendar__navigation__label {
  font-size: 16px !important;
  font-weight: 600 !important;
  font-family: "Metropolis" !important;
}

.react-calendar__viewContainer {
  margin-top: 10px !important;
}

.react-calendar__month-view__weekdays {
  font-size: 16px !important;
  font-weight: 600 !important;
  font-family: "Metropolis" !important;
  color: #fafafa !important;
  text-decoration: none !important;
}

.react-calendar__month-view__weekdays__weekday {
  height: 48px !important;
  width: 48px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.react-calendar__month-view__days {
  font-size: 16px !important;
  font-weight: 600 !important;
  font-family: "Metropolis" !important;
  color: #fafafa !important;
  text-decoration: none !important;
}

.react-calendar__month-view__days__day {
  height: 44px !important;
  width: 44px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 6px !important;
  background-color: #383434 !important;
}

.react-calendar__month-view__days {
  gap: 7px !important;
  flex: none !important;
  justify-content: center !important;
}

.react-calendar__month-view__days > button {
  flex: none !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
  background-color: transparent !important;
}

/* .react-calendar__tile--now {
    background: linear-gradient(
      90deg,
      #fef9cc 0%,
      #f6dbd4 40%,
      #f1c8d9 75%,
      #f0c2db 100%
    ) !important;
    color: #0b0a0a !important;
  } */

.react-calendar__tile--active {
  background: linear-gradient(
    90deg,
    #fef9cc 0%,
    #f6dbd4 40%,
    #f1c8d9 75%,
    #f0c2db 100%
  ) !important;
  color: #0b0a0a !important;
}
